
import { defineComponent, onMounted, reactive } from "vue";
import mAxiosApi from "@/api";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "kt-activite",
  props: {
    widgetClasses: String,
    myList: {default : [] as any },
    mField: String,
    colors: [] as any,
  },
  setup(props) {
    const state = reactive({
      list: [] as any,
      loaderEnabled: true,
      projet: 0,
      series: [] as any,
      options : {} as any,
      labCol : [] as any,

    });

    const zmyList = props.myList as any;
    const zmField = props.mField as any;

    onMounted(async () => {

      state.options = {
        colors:[],
        chart: {
          width: 250,
          height: 500,
          type: 'donut',
          animations: {
          enabled: false,
          animateGradually: {
                enabled: false
            },
            dynamicAnimation: {
                enabled: false
            }
          }
        },
        plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      total: {
                        showAlways: true,
                        show: true
                      }
                    }
                  }
                }
              },
        labels: [],
            legend: {
              position: "bottom",
              horizontalAlign: "left",
              offsetX: 0
            },
      };

      //console.log(zmField, zmyList);      

      props.myList.forEach(
        function(item: any){
          
          let filItem = state.options.labels.findIndex(el => el == item[zmField])
          
          if (filItem == -1){
            state.options.labels.push(item[zmField])
            state.options.colors.push("#"+props.colors[state.options.colors.length])
            state.labCol.push({ label: item[zmField], color: props.colors[state.options.colors.length-1] });
            state.series.push(1)
          }else {
            state.series[filItem] = state.series[filItem] +1;
          } 

        }
      );

      props.myList .forEach(
        function(item: any){
           let filItem = state.labCol.find(el => el.label == item[zmField])
           item.color = filItem.color;
        }
      );

      state.loaderEnabled = false;
    });



    const router = useRouter();

    const clickChart = (event,chartContext, config) => {
      //console.log(state.series[config.seriesIndex].name);
      // router.push({  name: "ticketWst", params: { status: state.series[config.seriesIndex].name } });
    };


    return {
      state,
      clickChart
    };
  },
});
