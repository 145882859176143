
import { defineComponent, onMounted, reactive } from "vue";
import mAxiosApi from "@/api";
import * as moment from "moment";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "kt-activite",
  props: {
    widgetClasses: String,
    myList: {default : [] as any },
    mField: String,
  },
  emits: ["affTicket"],
  setup(props, { emit }) {

  //moment.default.locale("fr");

    const state = reactive({
      list: [] as any,
      loaderEnabled: true,
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
      masks: {
        weekdays: 'WWWW',
      },
      attributes: [] as any,

    });
    const { t } = useI18n()

    const zmyList = props.myList as any;
    const zmField = props.mField as any;

    const clickTicket = (data) => {
      //console.log(data)
      emit("affTicket", data);
    }

    onMounted(async () => {



      zmyList.forEach(
        function(item: any){
          if(item.tis_date_planif || item.liv_date_liv) {
            // console.log(item.tis_date_planif, moment.default(item.tis_date_planif).toDate())
            state.attributes.push(
              {
                key: item.tis_seq,
                customData: {
                  title: ""+item.tic_c_label + "",
                  item: item,
                  tooltip: t("Ticket") + " #"+item.tic_seq +" - " + t("Libellé :") + " " +item.tic_c_label,
                },
                dates: item.tis_date_planif ? moment.default(item.tis_date_planif).toDate() : moment.default(item.liv_date_liv).toDate(),
              },
            )

          }


        }
      );
      
      state.loaderEnabled = false;
    });


    return {
      state,
      clickTicket,zmyList
    };
  },
});
